import React from 'react';
import { Box, Unstable_Grid2 as Grid, useTheme } from '@mui/material';

import { Button, MultipleDepartmentEditor, Select, Switch, Text, TextField } from 'components';
import { useToast } from 'core/toast/hooks';
import { useHasMobileView } from 'hooks/useHasMobileView';
import { useTranslations } from 'hooks/useTranslations';

import { CustomString } from 'config/translations';
import { UseMutation, UseQuery } from 'useQuery';
// eslint-disable-next-line no-restricted-imports
import { deliveryOptions, NSCardDeliveryOption, NSProviderData } from 'useQuery/useOrganisations';

interface NSProviderProps {
  organisationId: string;
  nsData: NSProviderData;
}

function NSProvider({ organisationId, nsData }: NSProviderProps) {
  const translations = useTranslations('nsProvider');
  const { addToast } = useToast();
  const theme = useTheme();
  const { hasMobileView } = useHasMobileView();

  const [departmentOrder, setDepartmentOrder] = React.useState<string[]>([]); // Track department order
  const [agreement, setAgreement] = React.useState<string>();
  const [department, setDepartment] = React.useState<Record<string, string>>({});
  const [company, setCompany] = React.useState<string>();

  const [cardDeliveryOption, setCardDeliveryOption] = React.useState<NSCardDeliveryOption>();
  const [splitBilling, setSplitBilling] = React.useState<boolean>(false);
  const [officeStreet, setOfficeStreet] = React.useState<string>('');
  const [officeHouseNumber, setOfficeHouseNumber] = React.useState<string>('');
  const [officeAddition, setOfficeAddition] = React.useState<string>('');
  const [officeZipcode, setOfficeZipcode] = React.useState<string>('');
  const [officeCity, setOfficeCity] = React.useState<string>('');

  const { refetch: refetchOrganisationData, isLoading: loadingOrganisationData } =
    UseQuery.organisation({ id: organisationId });
  const { mutateAsync: mutateAsyncUpdateNSProvider, isLoading: loadingUpdateNSProvider } =
    UseMutation.updateOrganisationProviders();

  React.useEffect(() => {
    setAgreement(nsData?.agreement ?? '');
    setDepartment(nsData?.department || {});
    setDepartmentOrder(Object.keys(nsData?.department || {})); // Initialize order with existing keys
    setCompany(nsData?.company ?? '');
    setCardDeliveryOption(nsData?.card_delivery_option);
    setSplitBilling(nsData?.split_billing ?? false);
    setOfficeStreet(nsData?.office_address?.street ?? '');
    setOfficeHouseNumber(nsData?.office_address?.house_number ?? '');
    setOfficeAddition(nsData?.office_address?.addition ?? '');
    setOfficeZipcode(nsData?.office_address?.zipcode ?? '');
    setOfficeCity(nsData?.office_address?.city ?? '');
  }, [nsData]);

  const isAgreementUnchanged = nsData?.agreement === agreement;
  const isDepartmentUnchanged = JSON.stringify(nsData?.department) === JSON.stringify(department);
  const isCompanyUnchanged = nsData?.company === company;
  const isCardDeliveryUnchanged = cardDeliveryOption === nsData?.card_delivery_option;
  const isSplitBillingUnchanged =
    nsData === undefined || nsData.split_billing === undefined
      ? true
      : splitBilling === nsData.split_billing
      ? true
      : splitBilling === false;
  const isOfficeAddressChanged =
    officeStreet !== nsData?.office_address?.street ||
    officeHouseNumber !== nsData?.office_address?.house_number ||
    officeZipcode !== nsData?.office_address?.zipcode ||
    officeCity !== nsData?.office_address?.city;

  const isAgreementInvalid = agreement === undefined || agreement === '';
  const isDepartmentEmpty = Object.values(department).length === 0;
  const isLoading = loadingUpdateNSProvider || loadingOrganisationData;
  const isCardDeliveryOptionInvalid =
    cardDeliveryOption === undefined ||
    (cardDeliveryOption === 'Office' &&
      (!officeStreet || !officeHouseNumber || !officeZipcode || !officeCity));

  const disabledSave =
    (!isOfficeAddressChanged &&
      isAgreementUnchanged &&
      isDepartmentUnchanged &&
      isCompanyUnchanged &&
      isCardDeliveryUnchanged &&
      isSplitBillingUnchanged) ||
    isAgreementInvalid ||
    isDepartmentEmpty ||
    isLoading ||
    isCardDeliveryOptionInvalid;

  async function updateNSProvider() {
    if (agreement === undefined || agreement === '' || Object.values(department).length === 0) {
      return;
    }

    try {
      await mutateAsyncUpdateNSProvider({
        id: organisationId,
        providers: {
          NS: {
            agreement,
            department,
            company: company === '' ? undefined : company,
            card_delivery_option: cardDeliveryOption as NSCardDeliveryOption,
            split_billing: splitBilling,
            office_address: {
              street: officeStreet,
              house_number: officeHouseNumber,
              addition: officeAddition,
              zipcode: officeZipcode,
              city: officeCity,
              country_code: 'NL',
            },
          },
        },
      });
      await refetchOrganisationData();
      addToast({ title: translations.updatedSuccessfully, severity: 'success' });
    } catch (error) {
      addToast({ error, severity: 'error' });
    }
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          padding: 2,
          marginBottom: 2,
          border: 1,
          borderColor: 'divider',
          alignItems: 'flex-start',
          borderRadius: 1,
          position: 'relative',
          maxWidth: `calc(100vw - ${theme.spacing(hasMobileView ? 4 : 6)})`,
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: hasMobileView ? 'row' : 'column',
            justifyContent: hasMobileView ? 'space-between' : undefined,
          }}
        >
          <Text.Bold>{translations.NSBusinessCard}</Text.Bold>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: hasMobileView ? 'column' : 'row',
          }}
        >
          {/* Departments */}
          <Box sx={[!hasMobileView ? { width: '50%' } : {}]}>
            <Grid
              container
              spacing={1}
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ paddingBottom: 2 }}>
                <TextField
                  type="text"
                  label={translations.agreement}
                  value={agreement ?? ''}
                  onChange={setAgreement}
                  required
                />
              </Box>
              <Box sx={{ paddingBottom: 2 }}>
                <TextField
                  type="text"
                  label={translations.company}
                  value={company ?? ''}
                  onChange={setCompany}
                  required
                />
              </Box>
            </Grid>
            <MultipleDepartmentEditor
              department={department}
              onChange={setDepartment}
              departmentOrder={departmentOrder}
              setDepartmentOrder={setDepartmentOrder}
            />
          </Box>

          {/* Delivery Address */}
          <Box sx={!hasMobileView ? { width: '50%' } : {}}>
            <Grid
              spacing={1}
              sx={[{ paddingTop: 2, paddingBottom: 2 }, !hasMobileView ? { display: 'flex' } : {}]}
            >
              <Box sx={[!hasMobileView ? { marginRight: 2 } : { marginBottom: 2 }]}>
                <Select
                  label={translations.cardDeliveredAt}
                  mode="single"
                  options={[
                    ...deliveryOptions.map((option: NSCardDeliveryOption) => ({
                      id: option,
                      label: option as CustomString,
                    })),
                  ]}
                  value={cardDeliveryOption}
                  onChange={setCardDeliveryOption}
                />
              </Box>
              <Switch
                label={translations.splitBilling}
                checked={splitBilling}
                onChange={setSplitBilling}
              />
            </Grid>
            {cardDeliveryOption === 'Office' && (
              <Grid spacing={1} sx={{ width: '100%' }}>
                <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                  <Text.Bold>{translations.officeAddress}</Text.Bold>
                </Box>
                <Box
                  sx={{
                    paddingBottom: 2,
                    width: '100%',
                  }}
                >
                  <TextField
                    label={translations.streetName}
                    type="text"
                    onChange={setOfficeStreet}
                    value={officeStreet}
                  />
                </Box>
                <Box
                  sx={{
                    paddingBottom: 2,
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Box sx={{ marginRight: 2 }}>
                    <TextField
                      label={translations.houseNumber}
                      type="text"
                      onChange={setOfficeHouseNumber}
                      value={officeHouseNumber}
                    />
                  </Box>
                  <TextField
                    label={translations.addition}
                    type="text"
                    onChange={setOfficeAddition}
                    value={officeAddition}
                  />
                </Box>
                <Box
                  sx={{
                    paddingBottom: 2,
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Box sx={{ marginRight: 2 }}>
                    <TextField
                      label={translations.zipcode}
                      type="text"
                      onChange={setOfficeZipcode}
                      value={officeZipcode}
                    />
                  </Box>
                  <TextField
                    label={translations.city}
                    type="text"
                    onChange={setOfficeCity}
                    value={officeCity}
                  />
                </Box>
              </Grid>
            )}
          </Box>
        </Box>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
          }}
        >
          <Button
            label={translations.save}
            disabled={disabledSave}
            loading={loadingUpdateNSProvider}
            onClick={updateNSProvider}
          />
        </Grid>
      </Box>
    </>
  );
}

export { NSProvider };
